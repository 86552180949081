import { useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import theme from '@mui/theme';
import Typography from '@mui/material/Typography';
import { FieldParagraphProps } from '@model/paragraph-props.model';
import { FeaturedImageInfoItemModel, FeaturedImageModel } from '@model/featured-image.model';
import { absoluteUrl, transformTitleId } from '@utils/functions/utils';
import Box from '@mui/material/Box';

const Stack = dynamic(() => import('@mui/material/Stack'));
const Divider = dynamic(() => import('@mui/material/Divider'));
const Icon = dynamic(() => import('@mui/material/Icon'));
const Grid = dynamic(() => import('@mui/material/Grid'));
const GridContainer = dynamic(() => import('@atoms/GridContainer/GridContainer'));
const Row = dynamic(() => import('@atoms/CustomGrid/Row'));
const BackgroundTransitionBox = dynamic(() => import('@atoms/BackgroundTransitionBox/BackgroundTransitionBox'));

const withoutSDGDataInfo = (items: FeaturedImageInfoItemModel[]) => items.filter((item) => item?.iconName !== 'spa');

const FeaturedImageParagraph = (props: FieldParagraphProps) => {
  const {
    fieldParagraph,
    fieldParagraph: { field_media, behavior_settings, field_title, featured_info_table },
  } = props;

  const parsedInfoItems = featured_info_table?.map((info) => {
    const [iconName, name] = info.split('|');
    return {
      iconName,
      name,
    };
  });

  const data = useMemo(() => {
    const newData: FeaturedImageModel = {
      anchorData: fieldParagraph.behavior_settings?.style_options?.toc?.label || '',
      transition: behavior_settings?.style_options?.transition?.css_class,
      colorVariant: behavior_settings?.style_options?.color_scheme?.css_class,
      direction: behavior_settings?.style_options?.vertical_lines?.css_class || 'none',
      image: {
        imageAlt: field_media?.field_media_image?.resourceIdObjMeta?.alt,
        imageUrl: absoluteUrl(field_media?.field_media_image?.uri?.url),
        width: field_media?.field_media_image?.resourceIdObjMeta?.width,
        height: field_media?.field_media_image?.resourceIdObjMeta?.height,
      },
      info: {
        title: field_title || '',
        items: parsedInfoItems || [],
      },
    };

    return newData;
  }, [fieldParagraph]);

  const [element, setElement] = useState<Element>(null);
  const [boxHeight, setBoxHeight] = useState<number>(0);

  const handleResize = (element: Element) => () => {
    if (!element) return;
    setBoxHeight(element.clientHeight);
  };

  const handleBoxRef = (el: Element) => {
    if (!el) return;
    setElement(el);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize(element));

    return () => {
      window.removeEventListener('resize', handleResize(element));
    };
  }, [element]);

  const { info, image, colorVariant, direction, transition } = data || {};
  const hasInfo = useMemo(() => !!info?.items?.length, [info]);

  if (!data) return null;

  return (
    <BackgroundTransitionBox
      anchor-data={transformTitleId(data.anchorData || '')}
      linesDirection={direction}
      transition={transition}
      colorVariant={colorVariant}
    >
      <Box pb={{ xs: theme.spacing(boxHeight - 53), lg: 0 }}>
        <Box
          position="relative"
          pt={{ xs: theme.spacing(80), lg: theme.spacing(104) }}
          pb={{ xs: theme.spacing(80), lg: theme.spacing(104) }}
        >
          {hasInfo && (
            <GridContainer
              sx={{
                position: 'absolute',
                zIndex: 1,
                height: '100%',
              }}
            >
              <Row height={{ xs: `calc(100% - ${theme.spacing(160)})`, lg: `calc(100% - ${theme.spacing(208)})` }}>
                <Grid item xs={1} />
                <Grid item xs={10} lg={4}>
                  <Box height="100%" display="flex" alignItems={{ xs: 'flex-end', lg: 'center' }}>
                    <Box
                      borderRadius={theme.spacing(24)}
                      ref={handleBoxRef}
                      flex={1}
                      overflow="hidden"
                      color="common.white"
                      sx={{
                        transform: { xs: `translateY(calc(100% - ${theme.spacing(53)}))`, lg: 'none' },
                        background: 'linear-gradient(133.03deg, #00000080 -16.9%, #000000 37.51%)',
                      }}
                    >
                      <Box
                        textAlign="center"
                        py={{ xs: theme.spacing(16), lg: theme.spacing(24) }}
                        bgcolor="alphaWhite.10"
                      >
                        <Typography variant="overline">{info.title}</Typography>
                      </Box>
                      <Box p={theme.spacing(32)}>
                        <Stack
                          direction="column"
                          spacing={theme.spacing(24)}
                          divider={<Divider orientation="horizontal" sx={{ borderColor: 'grey.600' }} flexItem />}
                        >
                          {withoutSDGDataInfo(info.items).map((item, index) => (
                            <Stack direction="row" key={index} spacing={theme.spacing(20)}>
                              <Icon>{item.iconName}</Icon>
                              <Typography fontWeight={500} variant={'bodyL'}>
                                {item.name}
                              </Typography>
                            </Stack>
                          ))}
                        </Stack>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Row>
            </GridContainer>
          )}
          <GridContainer>
            <Row>
              <Grid item xs={0} lg={hasInfo ? 3 : 1} />
              <Grid item xs={12} lg={hasInfo ? 8 : 10}>
                <Box position="relative" overflow="hidden" borderRadius={theme.spacing(32)}>
                  <Image
                    width={image.width}
                    height={image.height}
                    src={image.imageUrl}
                    alt={image.imageAlt}
                    style={{ width: '100%', height: 'auto' }}
                  />
                </Box>
              </Grid>
            </Row>
          </GridContainer>
        </Box>
      </Box>
    </BackgroundTransitionBox>
  );
};

export default FeaturedImageParagraph;
